.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 100px 50px;
  background-color: #f5f5f5;
  z-index: 100;
}

.footer-links {
  text-decoration: none;
  color: #d7d7d6;
}

.footer-links:hover {
  color: #ededed;
  text-decoration: line-through;
}

.first-section {
  flex-basis: 25%;
}

.second-section {
  flex-basis: 40%;
}

.third-section {
  flex-basis: 25%;
}

.animate-bottom {
  opacity: 0;
  transform: translateY(50px);
  animation: animate-section 0.5s ease-out forwards;
}

@keyframes animate-section {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
